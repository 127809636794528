import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItemList from './../../../../menu-items';
import useAuth from '../../../../hooks/useAuth';

const MenuList = () => {
    const { menuItem } = useAuth();
    const [visibleList, setVisibleList] = useState([]);

    useEffect(() => {
        setVisibleList(menuItem);
    }, [menuItem]);

    const navItems = menuItemList.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} visibleList={visibleList} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
