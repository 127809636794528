import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ArticleIcon from '@mui/icons-material/Article';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventIcon from '@mui/icons-material/Event';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CategoryIcon from '@mui/icons-material/Category';
import RuleIcon from '@mui/icons-material/Rule';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const icons = {
    home: AutoAwesomeMosaicIcon,
    bike: PedalBikeIcon,
    settings: MiscellaneousServicesIcon,
    profile: AccountCircleIcon,
    adminuser: SupervisorAccountIcon,
    list: ListAltIcon,
    article: ArticleIcon,
    product: ShoppingCartIcon,
    activity: EventIcon,
    rules: RuleIcon,
    noti_rules: NotificationsIcon,
    cat_rules: CategoryIcon,
    adv: LocalOfferIcon,
};

// eslint-disable-next-line
export default {
    items: [
        {
            id: 'navigation',
            type: 'group',
            children: [
                {
                    id: 'list',
                    title: 'List',
                    type: 'collapse',
                    icon: icons['list'],
                    children: [
                        {
                            id: 'list_exhibition_object',
                            title: 'Exhibition Object',
                            type: 'item',
                            icon: icons['article'],
                            url: '/list/exhibition_object',
                        },
                        {
                            id: 'list_category',
                            title: 'Exhibition Category',
                            type: 'item',
                            icon: icons['article'],
                            url: '/list/category',
                        },
                        {
                            id: 'list_page',
                            title: 'Other Pages',
                            type: 'item',
                            icon: icons['noti_rules'],
                            url: '/list/page',
                        },
                    ],
                },
                {
                    id: 'settings',
                    title: 'Services & Settings',
                    type: 'collapse',
                    icon: icons['settings'],
                    children: [
                        {
                            id: 'settings_profile',
                            title: 'Profile',
                            type: 'item',
                            icon: icons['profile'],
                            url: '/settings/profile',
                        },
                        {
                            id: 'settings_adminuser',
                            title: 'Admin User',
                            type: 'item',
                            icon: icons['adminuser'],
                            url: '/settings/admins',
                        },
                    ],
                }
            ],
        }
    ],
};
