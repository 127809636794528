import React from 'react';
import { Redirect } from 'react-router-dom';
import config from '../../config';
import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const home_url = config.home_url;

    if (isLoggedIn) {
        return <Redirect to={home_url} />;
    }

    return children;
};

export default GuestGuard;
