import axios from 'axios';
import config from '../config';

const API = {};
const base = config.api_base;

API.skipRefresh = (url) => {
    const list = [
        base + '/menuItem',
        base + '/admin/login',
        base + '/admin/refresh_token',
        base + '/admin/revoke_token'
    ];
    return list.includes(url);
}

API.get_menu_item = async () => {
    return axios.get(base + '/menuItem');
}

API.create_admin = async (email, password, nickname, tel, role_id) => {
    return axios.post(base + '/admin/create', {
        email,
        password,
        nickname,
        tel,
        role_id
    });
}

API.login = async (email, password) => {
    return axios.post(base + '/admin/login', { email, password });
}

API.refresh_token = async (refresh_token) => {
    return axios.post(base + '/admin/refresh_token', { refresh_token });
}

API.revoke_token = async (refresh_token) => {
    return axios.post(base + '/admin/revoke_token', { refresh_token });
}

API.get_admin_all = async () => {
    return axios.get(base + '/admin/info/all');
}

API.get_admin_me = async () => {
    return axios.get(base + '/admin/me');
}

API.get_admin = async (admin_id) => {
    return axios.get(base + '/admin/info/' + admin_id);
}

API.delete_admin = async (admin_id) => {
    return axios.delete(base + '/admin/' + admin_id);
}

API.update_adminInfo_me = async (nickname, tel, role_id) => {
    return axios.post(base + '/admin/updateInfo', {
        nickname,
        tel,
        role_id
    });
}

API.update_adminInfo = async (admin_id, nickname, tel, role_id) => {
    return axios.post(base + '/admin/updateInfo', {
        admin_id,
        nickname,
        tel,
        role_id
    });
}

API.update_password_me = async (old_password, new_password, confirm_password) => {
    return axios.post(base + '/admin/changePassword', {
        old_password,
        new_password,
        confirm_password
    });
}

API.update_password = async (admin_id, new_password, confirm_password) => {
    return axios.post(base + '/admin/ResetPassword', {
        admin_id,
        new_password,
        confirm_password
    });
}

API.get_role_all = async () => {
    return axios.get(base + '/role/all');
}

API.get_role_permitted = async () => {
    return axios.get(base + '/role/permitted');
}

API.get_user_by_stationId_qr = async (station_id, user_qr) => {
    return axios.get(base + '/admin/scanUser/' + station_id + '/' + user_qr);
}

API.get_station = async (station_id) => {
    return axios.get(base + '/station/info/' + station_id);
}

API.pay_deposit_by_userId = async (user_id) => {
    return axios.post(base + '/admin/payDeposit', { user_id });
}

API.return_deposit_by_userId = async (user_id) => {
    return axios.post(base + '/admin/returnDeposit', { user_id });
}

API.confirm_reservation = async (reservation_id) => {
    return axios.post(base + '/reservation/confirm', { reservation_id });
}

API.revert_reservation = async (reservation_id) => {
    return axios.post(base + '/reservation/revert', { reservation_id });
}

API.get_latestReservation_by_userId_stationId = async (user_id, station_id) => {
    return axios.get(base + '/reservation/latest/user/' + user_id + '/' + station_id);
}

API.lend_gear = async (user_id, station_id, adult_gear_qty, child_gear_qty) => {
    return axios.post(base + '/admin/lendGear', { user_id, station_id, adult_gear_qty, child_gear_qty });
}

API.cancel_gear = async (user_id) => {
    return axios.post(base + '/admin/cancelGear', { user_id });
}

API.return_gear = async (user_id, station_id, adult_gear_qty, child_gear_qty) => {
    return axios.post(base + '/admin/returnGear', { user_id, station_id, adult_gear_qty, child_gear_qty });
}

API.lost_gear = async (user_id, station_id, adult_gear_qty, child_gear_qty) => {
    return axios.post(base + '/admin/lostGear', { user_id, station_id, adult_gear_qty, child_gear_qty });
}

API.get_gear_lost_qty = async (user_id) => {
    return axios.get(base + '/admin/gearLostQty/' + user_id);
}

API.reset_gearReturn = async (user_id) => {
    return axios.post(base + '/admin/resetGearReturn', { user_id });
}

API.enable_pairing_by_userId = async (user_id) => {
    return axios.post(base + '/admin/enablePairing', { user_id });
}

API.disable_pairing_by_userId = async (user_id) => {
    return axios.post(base + '/admin/disablePairing', { user_id });
}

API.unpair_bike_by_staionId_bikeId = async (station_id, bike_id) => {
    return axios.post(base + '/admin/unpairBike', { station_id, bike_id });
}

//Tim 
API.get_user_all = async () => {
    return axios.get(base + '/user/info/all');
}

API.get_user = async (user_id) => {
    return axios.get(base + '/user/info/' + user_id);
}

API.get_user_current_pairing = async (user_id) => {
    return axios.get(base + '/pairing/current/user/' + user_id);
}

API.get_bike_all = async () => {
    return axios.get(base + '/bike/info/all');
}

API.get_reservation_all = async () => {
    return axios.get(base + '/reservation/info/all');
}

API.get_survey_all = () => {
    return axios.get(base + '/survey/');
}

API.get_survey = (id) => {
    return axios.get(`${base}/survey/${id}`);
}

API.add_survey = (data) => {
    return axios.post(`${base}/survey`, { data });
}

API.upd_survey = (id, data) => {
    return axios.put(`${base}/survey/${id}`, { data });
}

API.del_survey = (id) => {
    return axios.delete(`${base}/survey/${id}`);
}

API.get_surveyResult_all = () => {
    return axios.get(base + '/surveyResult/result');
}

API.get_userQuota = async () => {
    return axios.get(base + '/user/info/num');
}


API.get_exhibition_object_all = async () => {
    return axios.get(base + '/exhibition_object/cms_list');
}

API.update_exhibition_object = async (data) => {
    return axios.post(base + '/exhibition_object/update', { data: data });
}

API.get_category_all = async () => {
    return axios.get(base + '/category/cms_list');
}

API.get_category_filter = async () => {
    return axios.get(base + '/category/filter_list');
}

API.update_category = async (data) => {
    return axios.post(base + '/category/update', { data: data });
}

API.get_page_all = async() =>{
    return axios.get(base + '/page/cms_list');
}

API.update_page = async (data) => {
    return axios.post(base + '/page/update', { data: data });
}

API.upload_exhibition_object_image = async(image_form_data) => {
    return axios({
        method: 'post',
        url: base + '/exhibition_object/media/upload',
        data: image_form_data,
        headers: {'Content-Type': 'multipart/form-data' }
    })
}

API.get_seo = async() => {
    return axios.get(base + '/seo');
}

API.update_seo = async(data) => {
    return axios.post(base + '/seo/update' , {data : data});
}

API.upload_seo_image = async(data) => {
    return axios({
        method: 'post',
        url: base + '/seo/image',
        data: data,
        headers: {'Content-Type': 'multipart/form-data' }
    })
}

API.get_prioritized_all = async (type) => {
    return axios.get(base + '/prioritized/all/' + type);
}

export default API;
