import React from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => {
    const { ...rest } = props;

    return <MuiAlert ref={ref} {...rest} />;
});

export default Alert;
