import React, { useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { JWTProvider } from '../contexts/JWTContext';
import theme from './../themes';
import Routes from '../Routes';
import NavigationScroll from './NavigationScroll';
import Snackbar from '../component/Snackbar';

import { create } from 'jss';
import rtl from 'jss-rtl';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('./../compiled-lang/fr.json');
    case 'ro':
      return import('./../compiled-lang/ro.json');
    case 'zh':
      return import('./../compiled-lang/zh.json');
    default:
      return import('./../compiled-lang/en.json');
  }
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => {
  const customization = useSelector((state) => state.customization);
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(customization.locale).then(d => {
      setMessages(d.default);
    });
  }, [customization]);

  return (
    <React.Fragment>
      {messages && <IntlProvider
        locale={customization.locale}
        defaultLocale="en"
        messages={messages}
      >
        <NavigationScroll>
          <StylesProvider jss={jss}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <JWTProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Routes />
                    <Snackbar />
                  </LocalizationProvider>
                </JWTProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </StylesProvider>
        </NavigationScroll>
      </IntlProvider>}
    </React.Fragment>
  );
};

export default App;
