import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import config from './config';
import useAuth from './hooks/useAuth';

import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
import GuestGuard from './component/Auth/GuestGuard';
import AuthGuard from './component/Auth/AuthGuard';
import MinimalLayout from './layout/MinimalLayout';

const NotFound = lazy(() => import('./views/Pages/Error/Error1')); //404
const AuthLogin = lazy(() => import('./views/Login'));
const ExhibitionObject = lazy(() => import('./views/List/ExhibitionObject'));
const Category = lazy(() => import('./views/List/Category'));
const Page = lazy(()=> import('./views/List/Page'));

const Profile = lazy(() => import('./views/Settings/Profile'));
const AdminUserList = lazy(() => import('./views/Settings/AdminUserList'));

const ComingSoon = lazy(() => import('./views/Pages/Comingsoon'));

const Routes = () => {
    const homeUrl = config.home_url;
    const { menuItem } = useAuth();
    const location = useLocation();
    const [visibleList, setVisibleList] = useState([homeUrl]);

    useEffect(() => {
        init();
    }, [menuItem]);

    const init = () => {
        console.log(menuItem);
        let newVisibleList = [homeUrl];
        for (let key in menuItem) {
            if (menuItem[key].visible && menuItem[key].url) {
                newVisibleList.push(menuItem[key].url);
            }
        }
        setVisibleList(newVisibleList);
    };

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from="/" to={homeUrl} />
                    <Route path={['/login']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <GuestGuard>
                                        <Route path="/login" component={AuthLogin} />
                                    </GuestGuard>
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route
                        path={visibleList}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route path="/list/exhibition_object" component={ExhibitionObject} />
                                        <Route path="/list/category" component={Category} />
                                        <Route path="/list/page" component={Page} />

                                        <Route path="/settings/profile" component={Profile} />
                                        <Route path="/settings/admins" component={AdminUserList} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                    <Route
                        path={'/'}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route exact={true} component={NotFound} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
