// eslint-disable-next-line
export default {
    basename: '/',
    // api_base: 'https://api.apo-fc.hkrnd.com/api',
    api_base: 'https://api.apo-fc-dev.hkrnd.com/api',
    home_menu_item_id: 'list_category',
    home_url: '/list/category',
    theme: 'light',
    rtlLayout: false,
    i18n: 'en'
};
